@import "bootstrap/scss/bootstrap.scss";

$body-color: slateblue;

body {
  font-family: sans-serif;
  margin: 0;
}

.main {
  margin-top: 5%;
  margin-bottom: 5%;
}

input:valid {
  background-color: powderblue;
}
